<h2 mat-dialog-title class="m-0">Mis notas del colaborador</h2>

<mat-dialog-content class="mat-typography" style="height: 40vw; width: 70vw; padding: 3rem">
	<div class="w-100-p contenedor" fxLayout="row" fxLayoutAlign="end end">
		<div fxLayout="column" class="w-100-p" fxFlex="1 1 0" fxLayoutAlign="start start">
			<mat-slide-toggle class="font-size-15" [checked]="allperiod == 1" (change)="changeAllperiod($event)" color="green">
				Visualizar también las de otros periodos
			</mat-slide-toggle>
		</div>
		<div fxLayout="column" class="w-100-p" fxFlex="1 1 0" fxLayoutAlign="end end"></div>
		<div fxLayout="column" class="w-100-p" fxFlex="1 1 0" fxLayoutAlign="end end">
			<button mat-raised-button color="primary" [matMenuTriggerFor]="MenuNew" #menuTrigger="matMenuTrigger">Añadir</button>
		</div>

		<mat-menu #MenuNew="matMenu" class="menuMat">
			<div style="padding: 8px" (click)="$event.stopPropagation()">
				<div fxLayout="row" style="padding: 4px" fxLayoutAlign="center center">
					<mat-form-field subscriptSizing="dynamic" class="w-100-p" appearance="outline" style="min-width: 550px">
						<mat-label>Nueva nota</mat-label>
						<textarea style="min-height: 60px" matInput [(ngModel)]="newNotaName" required maxlength="1000"></textarea>
					</mat-form-field>
				</div>
				<div fxLayout="row" style="padding: 4px" fxLayoutAlign="center center">
					<div style="width: 100%">
						<button
							mat-raised-button
							color="primary"
							style="width: 100% !important"
							(click)="anadirNota(menuTrigger)"
							[disabled]="newNotaName.length == 0 || newNotaName.length > 1000"
						>
							Añadir
						</button>
					</div>
				</div>
			</div>
		</mat-menu>
	</div>
	@if(notas.length>0){
	<div class="w-100-p contenedor" fxLayout="row" fxLayoutAlign="center center">
		<table mat-table [dataSource]="notas" matSort id="tabla-configuracion" class="w-100-p" style="width: 100% !important">
			<ng-container matColumnDef="nota">
				<th mat-header-cell *matHeaderCellDef>Nota</th>
				<td mat-cell *matCellDef="let nota" class="p-12">
					<label>{{ nota.nota }}</label>
				</td>
			</ng-container>

			<ng-container matColumnDef="fecha">
				<th mat-header-cell *matHeaderCellDef>Fecha</th>
				<td mat-cell *matCellDef="let nota" class="p-12">
					<label>{{ parseDate(nota.creationDate) }}</label>
				</td>
			</ng-container>

			<ng-container matColumnDef="actions">
				<th mat-header-cell *matHeaderCellDef></th>
				<td mat-cell *matCellDef="let nota" class="p-12">
					<mat-icon class="clickable" [matMenuTriggerFor]="MenuEdit" #menuTrigger="matMenuTrigger" (click)="selectNota(nota.nota)">edit</mat-icon>
					<mat-icon class="clickable" style="color: red" [matMenuTriggerFor]="MenuDelete">delete</mat-icon>
					<mat-menu #MenuDelete="matMenu" class="menuMat">
						<div style="padding: 8px">
							<div fxLayout="row" style="padding: 4px" fxLayoutAlign="center center">¿Desea eliminar esta nota?</div>
							<div fxLayout="row" style="padding: 4px" fxLayoutAlign="center center">
								<div style="width: 100%">
									<button mat-raised-button color="primary" style="width: 100% !important" (click)="eliminar(nota)">Eliminar</button>
								</div>
							</div>
						</div>
					</mat-menu>
					<mat-menu #MenuEdit="matMenu" class="menuMat">
						<div style="padding: 8px" (click)="$event.stopPropagation()">
							<div fxLayout="row" style="padding: 4px" fxLayoutAlign="center center">
								<mat-form-field subscriptSizing="dynamic" class="w-100-p" appearance="outline" style="min-width: 550px">
									<mat-label>Editar nota</mat-label>
									<textarea style="min-height: 60px" matInput [(ngModel)]="notaActualizar" required maxlength="1000"></textarea>
								</mat-form-field>
							</div>
							<div fxLayout="row" style="padding: 4px" fxLayoutAlign="center center">
								<div style="width: 100%">
									<button
										mat-raised-button
										color="primary"
										style="width: 100% !important"
										(click)="editarNota(nota, menuTrigger)"
										[disabled]="nota.nota.length == 0 || nota.nota.length > 1000"
									>
										Editar
									</button>
								</div>
							</div>
						</div>
					</mat-menu>
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		</table>
	</div>
	<mat-paginator [length]="totalNotas" [pageIndex]="0" [pageSize]="8" (page)="pageChanged($event)" showFirstLastButtons></mat-paginator>
	}@else{
	<div class="w-100-p contenedor" style="margin-top: 15vw" fxLayout="row" fxLayoutAlign="center center">
		@if(allperiod==0){ No has añadido anotaciones aún sobre este colaborador de cara al proximo periodo }@else { No has añadido anotaciones aún sobre
		este colaborador }
	</div>

	}
</mat-dialog-content>
