import { Component, Input, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";
import { DialogNotasEvaluacionComponent } from "@fuse/components/dialog-notas-evaluacion/dialog-notas-evaluacion.component";
import { feedAnimations } from "app/animations";
import { EvaluationService } from "app/services/evaluation/evaluation.service";
import { ImagesService } from "app/services/helper/images.service";
import { PersonService } from "app/services/person/person.service";
import { PuestosService } from "app/services/puesto/puestos.service";
import moment from "moment";
import { PrivateFeedbackDialogComponent } from "../private-feedback-dialog/private-feedback-dialog.component";
import { TransferDialogComponent } from "../transfer-dialog/transfer-dialog.component";
@Component({
	selector: "colaborator-card",
	templateUrl: "./colaborator-card.component.html",
	styleUrls: ["./colaborator-card.component.scss"],
	animations: [feedAnimations],
})
export class ColaboratorCardComponent implements OnInit {
	@Input() isDoneTab;
	@Input() evaluation;
	@Input() period;
	@Input() aspectsList;
	@Input() userId;
	@Input() projectName;
	@Input() checkCurrent?;

	// Transferencia
	originalEvaluator: string = "";
	transferedTo: any;

	// Private feedback
	privateFeedbackList: any = [];
	privateFeedbackCount: number;

	// Plan de sucesicon
	filterPersonValue: string = "";
	persons: Array<any> = [];
	hasMorePersons: boolean;
	currentPagePersons: number;
	personSelected = null;

	isAfter = true;

	isDraftByEvaluator = false;
	isDoneByEvaluator = false;
	isBorradorCompletado = false;
	lessThan6months = false;
	aspectosOk = false;
	arrayAspectos = [0, 0, 0, 0];

	colorMap = {
		13: "#d2e1ed", // POT
		12: "#7c99b1", // PLAN
		10: "#7c99b1", // PLAN
		11: "#7c99b1", // PLAN
	};

	partial = null;
	// Revision de planes de acción
	actionPlansPendingsRevision: number;

	showNotDone = false;
	constructor(
		public dialog: MatDialog,
		private router: Router,
		private snackBar: MatSnackBar,
		private imagesSvc: ImagesService,
		private evaluationSvc: EvaluationService,
		private puestoSvc: PuestosService,
		private personSvc: PersonService
	) {}

	ngOnInit(): void {
		if (this.evaluation.entry && this.projectName == "syrsa") {
			const periodDate = new Date(moment(this.period.startPeriod).format("YYYY-MM-DD HH:mm:ss"));
			const entryDate = new Date(moment(this.evaluation.entry).add(6, "months").format("YYYY-MM-DD HH:mm:ss")); // Utiliza 'months' en lugar de 'm'
			const timePeriod = periodDate.getTime();
			const entry = entryDate.getTime();
			if (timePeriod < entry) {
				this.lessThan6months = true;
			}
		}

		// Puesto
		this.puestoSvc.getPuestoByPersonAndDate(this.evaluation.personId, this.period.id).subscribe((_puesto) => {
			if (_puesto) {
				this.evaluation.puesto = _puesto;
				// Autoevaluation
				this.evaluationSvc.getEvaluationForm(_puesto.id).subscribe((evForm) => {
					if (evForm) {
						this.evaluation.hasAutoevaluation = evForm.autoevaluation ? true : false;
						this.evaluationSvc.getEvaluationPartial(this.evaluation.personId, this.evaluation.personId, this.period.id).subscribe((data) => {
							if (!data || data.status !== "DONE") {
								this.evaluation.isAutoevaluationPending = true;
							} else {
								this.evaluation.isAutoevaluationPending = false;
							}
						});
					}
				});
			}
		});
		// Resultados
		this.evaluationSvc.getEvaluationResults(this.evaluation.personId, this.period.id).subscribe((_results) => {
			if (_results) {
				this.evaluation.result = _results.result;
				this.evaluation.competencyBlock = _results.competencyBlock;
				this.evaluation.potencial = _results.potencial;
				this.evaluation.formId = _results.formId;
			} else {
				if (this.checkCurrent) {
					this.showNotDone = true;
				}
			}
		});
		//comprobar si se ha hecho por evaluador
		this.evaluationSvc.getEvaluationPartial(this.evaluation.personId, this.userId, this.period.id).subscribe((data) => {
			if (data && data.status === "DRAFT") {
				this.partial = data;

				if (data.borrador_completo) this.isBorradorCompletado = true;
				this.isDraftByEvaluator = true;
			} else if (data && data.status === "DONE") {
				this.partial = data;
				this.isDoneByEvaluator = true;
			}
		});
		// Aspectos
		this.evaluationSvc.getPersonAspects(this.evaluation.personId, this.period.id).subscribe((data) => {
			this.evaluation.aspects = data;
			const _personAspect = data.find((a) => a.resultPersonId);

			if (this.projectName == "syrsa") {
				for (let elem of data) {
					if (elem.aspectId == 10) {
						if (elem.aspectValueId == 30) {
							this.arrayAspectos[1] = 1;
						}
						if (elem.aspectValueId == 31) {
							this.arrayAspectos[1] = 2;
						}
					}
					if (elem.aspectId == 11) {
						if (elem.resultPersonId) {
							this.arrayAspectos[2] = 1;
						}
					}
					if (elem.aspectId == 12) {
						if (elem.aspectValueId) {
							this.arrayAspectos[3] = 1;
						}
					}
					if (elem.aspectId == 13) {
						if (elem.aspectValueId) {
							this.arrayAspectos[0] = 1;
						}
					}
				}
				this.aspectosOk = this.checkAspects();
				console.log("arrayAspectos", this.aspectosOk);
			}

			// Set person
			if (_personAspect) {
				this.personSvc.getPerson(_personAspect.resultPersonId).subscribe((data) => {
					this.personSelected = data.id;
					this.filterPersonValue = data.displayName;
					this.persons = [];
				});
			}
		});
		// Trasnferencias
		if (this.period.isEnabledEvaluationTransfer) {
			if (this.evaluation.type === "TRANSFER") {
				this.personSvc.getPerson(this.evaluation.originalEvaluator).subscribe((data) => {
					this.originalEvaluator = data.displayName;
				});
			} else {
				this.evaluationSvc.isEvaluationTransfered(this.evaluation.personId, this.period.id).subscribe((data) => {
					if (data) {
						this.transferedTo = data;
					}
				});
			}
		}
		// Private feedback
		if (this.period.isEnabledPrivateFeedback) {
			this.evaluationSvc.getAllPrivateFeedbackRequestByPersonAndPeriod(this.evaluation.personId, this.period.id).subscribe((data) => {
				if (data) {
					this.privateFeedbackList = data;
					this.privateFeedbackCount = this.period.maxPrivateFeedbackPersons - this.privateFeedbackList.length;
				}
			});
		}
		// Planes de acción pendientes de revisión
		this.evaluationSvc.getActionsPlans(this.evaluation.personId, this.period.id).subscribe((data) => {
			const _actionPlansPendings = data.filter((ap) => !ap.isRevised);
			this.actionPlansPendingsRevision = _actionPlansPendings.length;
		});
		this.isDateAfter();
	}

	isDateAfter() {
		var cpep = new Date(this.period.endPeriod);
		var today = new Date();

		this.isAfter = cpep < today;
	}
	getAspectValue(aspects, aspectId) {
		if (aspects && aspects.length) {
			const _aspect = aspects.find((a) => a.aspectId === aspectId);
			return _aspect ? _aspect.aspectValueId : "";
		} else {
			return "";
		}
	}

	onSelectAspect(aspectId, aspectValue) {
		this.saveAspect(aspectId, aspectValue, "");
	}

	filterPersons(event: Event) {
		if (this.filterPersonValue.length > 2) {
			this.currentPagePersons = 0;
			this.persons = [];
			this.getPersons();
		}
	}

	getPersons() {
		this.personSvc.getPersons(this.filterPersonValue, this.currentPagePersons, 5).subscribe(
			(personsData) => {
				this.persons = personsData.elements;
			},
			(err) => {
				console.log(err);
			}
		);
	}

	clearPerson(aspectId) {
		this.personSelected = null;
		this.filterPersonValue = "";
		this.persons = [];
		this.saveAspect(aspectId, "", "");
	}

	selectPerson(aspectId, user) {
		this.saveAspect(aspectId, "", user.id);
		this.personSelected = user.id;
		this.filterPersonValue = user.displayName;
		this.persons = [];
	}

	saveAspect(aspectId, aspectValueId, aspectPersonId) {
		this.evaluationSvc.savePersonAspect(this.evaluation.personId, this.period.id, aspectId, aspectValueId, aspectPersonId).subscribe(
			(data) => {
				if (data.success) {
					document.getElementById(this.evaluation.personId + "-" + aspectId).style.borderColor = "greenyellow";
					document.getElementById(this.evaluation.personId + "-" + aspectId).style.color = "greenyellow";
				}
			},
			(err) => {
				document.getElementById(this.evaluation.personId + "-" + aspectId).style.borderColor = "red";
				document.getElementById(this.evaluation.personId + "-" + aspectId).style.color = "red";
			}
		);
		if ((this.projectName = "syrsa")) {
			if (aspectId == 10) {
				if (aspectValueId == 30) {
					this.arrayAspectos[1] = 1;
				}
				if (aspectValueId == 31) {
					this.arrayAspectos[1] = 2;
				}
				if (!aspectValueId) {
					this.arrayAspectos[1] = 0;
				}
			}
			if (aspectId == 11) {
				if (aspectPersonId) {
					this.arrayAspectos[2] = 1;
				} else {
					this.arrayAspectos[2] = 0;
				}
			}
			if (aspectId == 12) {
				if (aspectValueId) {
					this.arrayAspectos[3] = 1;
				} else {
					this.arrayAspectos[3] = 0;
				}
			}
			if (aspectId == 13) {
				if (aspectValueId) {
					this.arrayAspectos[0] = 1;
				} else {
					this.arrayAspectos[0] = 0;
				}
			}
			this.aspectosOk = this.checkAspects();
			console.log("arrayAspectos", this.aspectosOk);
		}
	}

	roundTwoDecimals(data) {
		if (!data) {
			return "-";
		} else if (data instanceof Number) {
			return data.toFixed(2);
		} else {
			return parseFloat(data).toFixed(2);
		}
	}

	getImagePerson(nickname: string) {
		return this.imagesSvc.getImageThumb(nickname);
	}

	goToDashboard(personId) {
		this.router.navigate(["/sied"], { queryParams: { person: personId } });
		// const url = this.router.serializeUrl(this.router.createUrlTree(['/sied'], {queryParams: { person: personId }} ));
		// window.open(url, '_blank');
	}

	goToEvaluationForm() {
		// Si la evaluación ya ha sido realizada, se carga el formulario dado por el servicio
		if (this.evaluation.formId) {
			const _params = {
				formId: this.evaluation.formId,
				personId: this.evaluation.personId,
				periodId: this.period.id,
				checkCurrent: this.checkCurrent,
			};
			this.router.navigate(["/evaluacion"], { queryParams: _params });
		}
		// Si no hay evaluación, crear la evaluación con el puesto antes de comenzar el periodo
		else {
			this.puestoSvc.getPuestoByPersonAndDate(this.evaluation.personId, this.period.id).subscribe((puesto) => {
				if (puesto) {
					this.evaluationSvc.getEvaluationForm(puesto.id).subscribe((data) => {
						if (data) {
							const _params = {
								formId: data.id,
								personId: this.evaluation.personId,
								periodId: this.period.id,
								checkCurrent: this.checkCurrent,
							};
							this.router.navigate(["/evaluacion"], { queryParams: _params });
						} else {
							this.showToast("No existe un formulario asociado al puesto del usuario.");
						}
					});
				} else {
					this.puestoSvc.getPuestoByPersonAndDate(this.evaluation.personId).subscribe((puesto) => {
						this.evaluationSvc.getEvaluationForm(puesto.id).subscribe((data) => {
							if (data) {
								const _params = {
									formId: data.id,
									personId: this.evaluation.personId,
									periodId: this.period.id,
									checkCurrent: this.checkCurrent,
								};
								this.router.navigate(["/evaluacion"], { queryParams: _params });
							} else {
								this.showToast("No existe un formulario asociado al puesto del usuario.");
							}
						});
					});
				}
			});
		}
	}

	openTransferDialog() {
		const dialogRef = this.dialog.open(TransferDialogComponent, {
			data: {
				transferedTo: this.transferedTo,
				personId: this.evaluation.personId,
				periodId: this.period.id,
				close: (_callback) => {
					this.transferedTo = _callback;
					dialogRef.close();
				},
			},
			panelClass: "panel-feedbalia",
			autoFocus: false,
			maxWidth: "50vw",
			minWidth: "40vw",
		});
	}

	openFeedbackDialog(privateFeedback?) {
		// Limite de private feedback
		if (!privateFeedback) {
			if (this.privateFeedbackList.length >= this.period.maxPrivateFeedbackPersons) {
				this.showToast("Límite de feedback privados a solicitar superado para este usuario.");
				return;
			}
		}
		const dialogRef = this.dialog.open(PrivateFeedbackDialogComponent, {
			data: {
				personId: this.evaluation.personId,
				periodId: this.period.id,
				privateFeedback,
				close: (_callback) => {
					if (_callback) {
						if (_callback.type === "ADD") {
							this.privateFeedbackList.push(_callback);
							this.privateFeedbackCount--;
						} else {
							this.privateFeedbackList = this.privateFeedbackList.filter((pf) => pf.id !== _callback.id);
							this.privateFeedbackCount++;
						}
					}
					dialogRef.close();
				},
			},
			panelClass: "panel-feedbalia",
			autoFocus: false,
			maxWidth: "50vw",
			minWidth: "40vw",
		});
	}

	sendUserPendingEvaluationMail(personId) {
		this.evaluationSvc.sendAutoevaluationMail(personId).subscribe(
			(data) => {
				if (data.success) {
					this.showSuccessDialog();
				}
			},
			(error) => {
				console.error(error);
				this.showErrorDialog();
			}
		);
	}

	getEvaluationStatusButton() {
		if ((!this.period.isActive && (!this.period.isEnabledSaveEvaluationBeforePeriod || this.isAfter)) || this.isDoneTab || this.isDoneByEvaluator) {
			return "Ver resultado";
		} else if (this.isDraftByEvaluator) {
			return "Continuar evaluación";
		} else {
			if (this.projectName == "syrsa") {
				return "Iniciar conv. de desarrollo";
			} else {
				return "Evaluar ahora";
			}
		}
	}

	private showSuccessDialog() {
		const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
			panelClass: ["panel-feedbalia"],
			disableClose: false,
			autoFocus: false,
			data: {
				confirmTitle: "Notificaciones por email enviadas correctamente.",
				showSuccess: true,
			},
		});
	}

	private showErrorDialog() {
		const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
			panelClass: ["panel-feedbalia"],
			disableClose: false,
			autoFocus: false,
			data: {
				confirmTitle: "Se ha producido un error al enviar las notificaciones.",
				showError: true,
			},
		});
	}

	private showToast(msg: string) {
		this.snackBar.open(msg, "X", {
			duration: 3000,
			verticalPosition: "top",
		});
	}

	private checkAspects() {
		if (this.period.isActive == false) {
			return true;
		}
		if (Number(this.period.id) < 347) {
			return true;
		}
		if (this.arrayAspectos[0] == 1 && this.arrayAspectos[1] == 2) {
			return true;
		} else if (this.arrayAspectos[0] == 1 && this.arrayAspectos[1] == 1 && this.arrayAspectos[2] == 1 && this.arrayAspectos[3] == 1) {
			return true;
		} else {
			return false;
		}
	}

	addNota() {
		const dialogRef = this.dialog.open(DialogNotasEvaluacionComponent, {
			data: {
				period: this.period,
				evaluado: this.evaluation.personId,
			},
			panelClass: "panel-feedbalia",
			autoFocus: false,
		});
	}
}
