import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";

@Component({
	selector: "dialog-info",
	templateUrl: "./dialog-info.component.html",
	styleUrls: ["./dialog-info.component.scss"],
})
export class DialogInfoComponent implements OnInit {
	titulo: string;
	contentDialog: string;

	textoDialogInfo = {
		siedActual: {
			titulo: "",
			contentDialog: "",
		},
		siedFuturo: {
			titulo: "",
			contentDialog: "",
		},
		historico: {
			titulo: "",
			contentDialog: "",
		},
		planAccion: {
			titulo: "",
			contentDialog: "",
		},
	};

	constructor(@Inject(MAT_DIALOG_DATA) public data: any, private translate: TranslateService) {
		this.textoDialogInfo.siedActual.titulo = this.translate.instant("evaluation.help.basic.title");
		this.textoDialogInfo.siedActual.contentDialog = this.translate.instant("evaluation.help.basic.description");
		this.textoDialogInfo.siedFuturo.titulo = this.translate.instant("evaluation.help.future.title");
		this.textoDialogInfo.siedFuturo.contentDialog = this.translate.instant("evaluation.help.future.description");
		this.textoDialogInfo.historico.titulo = this.translate.instant("evaluation.help.history.title");
		this.textoDialogInfo.historico.contentDialog = this.translate.instant("evaluation.help.history.description");
		this.textoDialogInfo.planAccion.titulo = this.translate.instant("evaluation.help.actionplan.title");
		this.textoDialogInfo.planAccion.contentDialog = this.translate.instant("evaluation.help.actionplan.description");
	}

	ngOnInit(): void {
		// console.log("data", this.data);

		if (this.data.tipoInfo === "sied-actual") {
			this.titulo = this.textoDialogInfo.siedActual.titulo;
			this.contentDialog = this.textoDialogInfo.siedActual.contentDialog.replace("   ", "<br><br>");
		} else if (this.data.tipoInfo === "sied-futuro") {
			this.titulo = this.textoDialogInfo.siedFuturo.titulo;
			this.contentDialog = this.textoDialogInfo.siedFuturo.contentDialog.replace("   ", "<br><br>");
		} else if (this.data.tipoInfo === "historico") {
			this.titulo = this.textoDialogInfo.historico.titulo;
			this.contentDialog = this.textoDialogInfo.historico.contentDialog.replace("   ", "<br><br>");
		} else if (this.data.tipoInfo === "plan-accion") {
			this.titulo = this.textoDialogInfo.planAccion.titulo;
			this.contentDialog = this.textoDialogInfo.planAccion.contentDialog.replace("   ", "<br><br>");
		}
	}
}
