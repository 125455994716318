<div id="panel-control" class="page-layout simple fullwidth">
	<div fxLayout="row wrap" class="p-24">
		<div @onRenderUp class="fuse-card card-border-top card-fullwidth">
			<mat-tab-group [(selectedIndex)]="selectedIndex">
				<mat-tab fxFlex="100" label="Puntuaciones de mi equipo en periodos anterirores"> </mat-tab>
			</mat-tab-group>

			<!-- Filters -->
			<div *ngIf="isBoss" class="p-36 filter-container" fxLayout="row">
				<div fxLayout="column" fxFlex="5 1 0">
					<div fxLayout="row wrap" class="statics-filters">
						<mat-form-field subscriptSizing="dynamic" dense appearance="outline" class="mr-8">
							<mat-label>{{ "evaluation.controlpanel.period" | translate }}</mat-label>
							<mat-select
								*ngIf="periodList && periodList.length"
								class="form-control"
								#filterPeriod
								[(value)]="selectedPeriod"
								(selectionChange)="onChangePeriod(filterPeriod.value)"
							>
								<mat-option *ngFor="let period of periodList" [value]="period.id">{{ period.name }}</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<!-- Aspects -->
					<!-- <div *ngIf="currentPeriod && currentPeriod.isEnabledEvaluationAspect" fxLayout="row wrap" style="margin-top: 20px">
						<div *ngFor="let aspect of aspectsList">
							<mat-form-field subscriptSizing="dynamic" *ngIf="aspect.type === 'SELECT'" dense appearance="outline" class="mr-8">
								<mat-label>{{ aspect.name }}</mat-label>
								<mat-select class="form-control" [(value)]="selectedAspect[aspect.id]">
									<mat-option value="" (click)="onChangeAspect(aspect.id, '')">Cualquier {{ aspect.name }}</mat-option>
									<mat-option *ngFor="let value of aspect.values" [value]="value.id" (click)="onChangeAspect(aspect.id, value.id)">{{
										value.name
									}}</mat-option>
								</mat-select>
							</mat-form-field>
						</div>
					</div> -->
				</div>
			</div>

			<!-- Evaluations -->
			<div *ngIf="evaluationsLoaded" @slideUpList fxLayout="row wrap" fxLayoutAlign="center center" class="p-36 cards-container">
				<ng-container *ngFor="let eval of evaluations">
					<div
						fxLayout="column"
						[ngClass]="eval.personId == user.id ? 'fuse-card card-border-top card-auto' : 'fuse-card card-border-top card-colaborador'"
					>
						<mat-icon *ngIf="eval.personId == user.id" class="icon-badge secundary-text">bookmark</mat-icon>
						<colaborator-card
							[isDoneTab]="selectedIndex == 1 ? true : false"
							[evaluation]="eval"
							[period]="currentPeriod"
							[aspectsList]="[]"
							[userId]="user.id"
							[checkCurrent]="true"
							[projectName]="project.name"
						></colaborator-card>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>
