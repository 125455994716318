import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { feedAnimations } from "app/animations";
import { EvaluationService } from "app/services/evaluation/evaluation.service";
import { OrganigramaService } from "app/services/organigrama/organigrama.service";
import { ProjectService } from "app/services/project/project.service";
import { UserService } from "app/services/user/user.service";
import { User } from "app/shared/models/user.interface";
import moment from "moment";

@Component({
	selector: "panel-colaboradores-actuales",
	templateUrl: "./panel-colaboradores-actuales.component.html",
	styleUrls: ["./panel-colaboradores-actuales.component.scss"],
	encapsulation: ViewEncapsulation.None,
	animations: [feedAnimations],
})
export class PanelColaboradoresActualesComponent implements OnInit {
	readonly STATUS = ["PENDING", "DONE", "ALL"];

	selectedIndex: number = 2;
	user: User;
	evaluations: Array<any>;
	periodList: Array<any>;
	departamentList: Array<any>;
	jobsList: Array<any>;
	aspectsList: Array<any>;

	selectedPeriod: string;
	currentPeriod: any;
	isBoss = false;

	evaluationsLoaded: boolean = false;
	project: any; //Almacena la configuración del proyecto

	constructor(
		private route: ActivatedRoute,
		private evaluationSvc: EvaluationService,
		private userSvc: UserService,
		private organigramaSvc: OrganigramaService,
		private projectSvc: ProjectService
	) {}

	ngOnInit(): void {
		this.route.queryParams.subscribe((params) => {
			this.userSvc.getDataUser().subscribe((data) => {
				this.user = data;

				// Cargar todos los periodos
				this.evaluationSvc.getEvaluationPeriods("EVALUATION").subscribe((data) => {
					// console.log('periodList', data);
					this.periodList = data;
					if (new Date(this.periodList[0].endPeriod) > new Date()) {
						this.periodList.shift();
					}
					console.log("Periodos", this.periodList);
					// Seleccionar el periodo mas cercano a la fecha actual
					this.selectPeriod(this.periodList);
					// Cargar las evaluaciones de último periodo
					this.loadEvaluations();
				});
				// Cargar los departamentos
				this.organigramaSvc.getAllDepartaments().subscribe((data) => {
					// console.log('departamentList', data);
					this.departamentList = data;
				});
			});
		});
		this.projectSvc.getProjectConfig().subscribe((data) => {
			this.project = data;
		});
	}

	loadEvaluations() {
		this.evaluations = [];
		let eval_ordenadas = [];
		this.evaluationsLoaded = false;
		this.loadPeriod().then(() => {
			// Si el periodo no está activo, no hay evaluaciones pendientes
			const _status = this.STATUS[this.selectedIndex];
			if (!(_status === "PENDING" && !this.currentPeriod.isActive)) {
				this.evaluationSvc.getAllEvaluationPartialCurrent(this.selectedPeriod, this.user.id).subscribe((data) => {
					this.evaluations = data;
					//Comprobamos si es boss
					for (let elem of this.evaluations) {
						if (elem.personId != this.user.id) {
							this.isBoss = true;
							break;
						}
					}
					//Eliminamos la autoev
					eval_ordenadas = eval_ordenadas.concat(this.evaluations.filter((ev) => ev.personId != this.user.id));

					this.evaluations = eval_ordenadas;
					this.evaluationsLoaded = true;
				});
			}
		});
	}

	loadPeriod() {
		return new Promise((resolve, reject) => {
			this.evaluationSvc.getEvaluationPeriodById(this.selectedPeriod).subscribe((data) => {
				this.currentPeriod = data;
				// Calcular si es periodo activo o pasado
				this.processPeriodDate();
				console.log("Current Period", this.currentPeriod);
				resolve(true);
				// Aspectos
				if (this.currentPeriod.isEnabledEvaluationAspect) {
					this.evaluationSvc.getEvaluationAspects().subscribe((data) => {
						this.aspectsList = data;
					});
				}
			});
		});
	}

	processPeriodDate() {
		if (!this.currentPeriod) {
			return;
		}
		const _startDate = moment(this.currentPeriod.startPeriod);
		const _endDate = moment(this.currentPeriod.endPeriod);
		const _now = moment();

		if (_now > _endDate) {
			this.currentPeriod.isActive = false;
			this.currentPeriod.isLast = true;
		} else if (_now.isBetween(_startDate, _endDate) || this.currentPeriod.isEnabledSaveEvaluationBeforePeriod) {
			this.currentPeriod.isActive = true;
			this.currentPeriod.isLast = false;
		} else {
			this.currentPeriod.isActive = false;
			this.currentPeriod.isLast = false;
		}
	}

	private selectPeriod(periods) {
		let findCurrentPeriod = false;
		// Si la fecha actual está entre el comienzo y un final de un periodo, se selecciona como adtivo
		periods.forEach((p) => {
			const startPeriod = moment(p.startPeriod);
			const endPeriod = moment(p.endPeriod);
			const now = moment();
			if (now.isBetween(startPeriod, endPeriod, undefined, "[]")) {
				findCurrentPeriod = true;
				this.selectedPeriod = p.id;
			}
		});
		// Si no se ha encontrado periodo activo, se selecciona el úlitmo.
		if (!findCurrentPeriod) {
			this.selectedPeriod = periods[0].id;
		}
	}

	onChangePeriod(value) {
		this.selectedPeriod = value;
		this.loadEvaluations();
	}

	applyFilters() {}
}
