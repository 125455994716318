import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { EvaluationService } from "app/services/evaluation/evaluation.service";
import { ImagesService } from "app/services/helper/images.service";
import { PersonService } from "app/services/person/person.service";
import { UserService } from "app/services/user/user.service";
import { User } from "app/shared/models/user.interface";
import moment from "moment";

@Component({
	selector: "app-private-feedback-dialog",
	templateUrl: "./private-feedback-dialog.component.html",
	styleUrls: ["./private-feedback-dialog.component.scss"],
})
export class PrivateFeedbackDialogComponent implements OnInit {
	user: User;
	responseToSend: string = "";

	filterPersonValue: string = "";
	persons: Array<any> = [];
	hasMorePersons: boolean;
	currentPagePersons: number;
	personSelected = null;

	errorMsg: string = "";

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private userSvc: UserService,
		private personSvc: PersonService,
		private imagesSvc: ImagesService,
		private evaluationSvc: EvaluationService,
	) {}

	ngOnInit(): void {
		this.userSvc.getDataUser().subscribe((data) => {
			this.user = data;
		});
	}

	filterPersons(event: Event) {
		if (this.filterPersonValue.length > 2) {
			this.currentPagePersons = 0;
			this.persons = [];
			this.getPersons();
		}
	}

	getPersons() {
		this.personSvc.getPersons(this.filterPersonValue, this.currentPagePersons, 5).subscribe(
			(personsData) => {
				this.persons = personsData.elements;
			},
			(err) => {
				console.log(err);
			},
		);
	}

	clearPerson() {
		this.personSelected = null;
		this.filterPersonValue = "";
		this.persons = [];
	}

	selectPerson(user) {
		this.personSelected = user.id;
		this.filterPersonValue = user.displayName;
		this.persons = [];
	}

	getImagePerson(nickname: string) {
		return this.imagesSvc.getImageThumb(nickname);
	}

	formatDate(timestamp: number) {
		return moment(timestamp).format("DD/MM/YYYY HH:mm");
	}

	solicitarFeedback() {
		const body = {
			personResponseId: this.personSelected,
			personAboutId: this.data.personId,
			periodId: this.data.periodId,
		};
		this.evaluationSvc.setPrivateFeedback(body).subscribe((data) => {
			if (data.success) {
				const _callback = {
					type: "ADD",
					id: data.id,
					personId: this.personSelected,
					personResponseName: this.filterPersonValue,
				};
				this.data.close(_callback);
			} else if (data.error) {
				this.errorMsg = data.error;
			}
		});
	}

	removeSolicitud() {
		const _privateFeedbackId = this.data.privateFeedback.id;
		this.evaluationSvc.deletePrivateFeedback(_privateFeedbackId).subscribe((data) => {
			if (data.success) {
				const _callback = {
					type: "DELETE",
					id: _privateFeedbackId,
				};
				this.data.close(_callback);
			}
		});
	}

	close() {
		this.data.close();
	}

	setResponse(evt) {
		this.responseToSend = evt.target.value;
	}

	sendResponse() {
		if (!this.responseToSend.length) {
			this.errorMsg = "El campo respuesta no puede estar vacio.";
			return;
		}
		const _privateFeedback = Object.assign({}, this.data.privateFeedback);
		_privateFeedback.response = this.responseToSend;
		this.evaluationSvc.setPrivateFeedback(_privateFeedback).subscribe((data) => {
			if (data.success) {
				this.data.close();
			} else if (data.error) {
				this.errorMsg = data.error;
			}
		});
	}
}
