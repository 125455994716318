@if(permisos.r || permisosPDA.r){
<div id="sied" class="page-layout simple fullwidth">
	<div *ngIf="isEvaluator === false" fxLayout="row wrap" class="p-24">
		<h1>No tienes permisos para ver el dashboard de este usuario.</h1>
	</div>

	<div *ngIf="isEvaluator" fxLayout="row wrap" class="p-24">
		@if(permisos.r){
		<div fxLayout="column" fxFlex="40" class="user-container px-16">
			<div class="fuse-card card-border-top card-sied card-sied-actual p-16 py-28" @onRenderUp>
				<mat-icon class="btn-help" (click)="openDialogInfo('sied-actual')">help</mat-icon>

				<div fxLayout="row" class="resumen">
					<div *ngIf="person" fxFlex="40" fxLayout="column" fxLayoutAlign="center center" class="px-12">
						<div class="person-avatar" fxLayout="row" fxLayoutAlign="center flex-start">
							<img class="img-circle" [src]="getImagePerson(person.username)" [alt]="person.displayName" />
						</div>
						<h5 class="m-0 my-8 name-user">{{ person.displayName }}</h5>
						<div *ngIf="mainPeriod" class="w-100-p">
							<button
								*ngIf="!mainPeriod.isLast && !mainPeriod.isActive"
								[disabled]="!evalForm?.autoevaluation && isSelfPage"
								mat-raised-button
								class="btn-feedbalia w-100-p"
								(click)="openEvaluation('main')"
							>
								{{ "person_card.sied.auto" | translate }}
							</button>
							<button
								*ngIf="mainPeriod.isActive"
								[disabled]="!evalForm?.autoevaluation && isSelfPage"
								mat-raised-button
								class="btn-feedbalia w-100-p"
								(click)="openEvaluation('main')"
							>
								{{ "person_card.sied.auto" | translate }}
							</button>
							<button *ngIf="mainPeriod.isLast" mat-raised-button class="btn-feedbalia w-100-p" (click)="openEvaluation('last')">
								{{ "person_card.sied.showScore" | translate }}
							</button>
						</div>
					</div>
					<div *ngIf="mainPeriod" fxFlex="60" fxLayout="column" class="datos-sied-actual px-16">
						<div fxLayout="column">
							<h5 class="text-theme m-0 mb-4">{{ mainPeriod.name }}</h5>
							<div fxLayout="row" fxLayoutAlign="flex-start center">
								<mat-icon class="icon-calendar">calendar_today</mat-icon>
								<h6 *ngIf="mainPeriod.isActive" class="m-0 pl-4 font-size-12">
									{{ "evaluation.time.to" | translate }} {{ formatDate(mainPeriod.endPeriod) }}
								</h6>
								<h6 *ngIf="mainPeriod.isLast" class="m-0 pl-4 font-size-12">{{ "evaluation.finishedPeriod" | translate }}</h6>
								<h6 *ngIf="!mainPeriod.isLast && !mainPeriod.isActive" class="m-0 pl-4 font-size-12">
									Disponible del {{ formatDate(mainPeriod.startPeriod) }} al {{ formatDate(mainPeriod.endPeriod) }}
								</h6>
							</div>
						</div>
						<div fxFlex="1 1 0" fxLayout="row" fxLayoutAlign="space-between flex-end" class="px-8">
							<div fxLayout="column" fxLayoutAlign="center center">
								<h3 class="m-0 font-size-22 text-theme">{{ currentScore }}</h3>
								<h6 class="m-0 font-size-8">{{ "evaluation.sied.currentScore" | translate }}</h6>
							</div>
							<div *ngIf="avgScore" fxLayout="column" fxLayoutAlign="center center">
								<h3 class="m-0 font-size-22 text-theme">{{ avgScore }}</h3>
								<h6 class="m-0 font-size-8">{{ "evaluation.sied.avgScore" | translate }}</h6>
							</div>
							<div *ngIf="actionPlans" fxLayout="column" fxLayoutAlign="center center">
								<h3 class="m-0 font-size-22 text-theme">{{ actionPlans.length }}</h3>
								<h6 class="m-0 font-size-8">{{ "evaluation.sied.total.actions" | translate }}</h6>
							</div>
						</div>
					</div>
					<div *ngIf="mainPeriod == null" fxFlex="60" fxLayout="column" class="datos-sied-actual px-16">
						<div fxLayout="row" fxLayoutAlign="flex-start center">
							<mat-icon class="icon-calendar">calendar_today</mat-icon>
							<h6 class="m-0 pl-4 font-size-12">{{ "evaluation.noPeriod" | translate }}</h6>
						</div>
					</div>
				</div>

				<!-- Periodo de revisión -->
				<div
					*ngIf="evaluationPanel?.showPanelEvaluations && paRevisionPeriod && isSelfPage"
					class="evaluation-question-container mt-24 p-12"
					style="background: #f9000045"
				>
					<h3 class="text-center text-danger" style="font-size: 16px">
						Periodo de revisión de planes de acción activo. Reúnete con tus colaboradores para llevar a cabo la revisión.
					</h3>
					<div *ngIf="evaluation" fxLayout="row wrap" fxLayoutAlign="center center">
						<button class="action-button btn-danger pointer mr-16" (click)="openPanelControl(2)">Revisar ahora</button>
					</div>
				</div>

				<!-- Control panel -->
				<ng-container *ngIf="project?.name == 'leroymerlin'">
					<div *ngIf="evaluationPanel?.showPanelEvaluations && isSelfPage" fxLayout="row" class="evaluation-panel mt-24">
						<div fxFlex="33" class="p-16 pendings" (click)="openPanelControl(0)">
							<h1 class="m-0">{{ evaluationPanel.totalPendings }}</h1>
							<p class="m-0">{{ "evaluation.pendings" | translate }}</p>
						</div>
						<div fxFlex="33" class="p-16 sended" (click)="openPanelControl(1)">
							<h1 class="m-0">{{ evaluationPanel.totalDone }}</h1>
							<p class="m-0">{{ "evaluation.sendings" | translate }}</p>
						</div>
						<div fxFlex="33" class="p-16 totals" (click)="openPanelControl(2)">
							<h1 class="m-0">{{ evaluationPanel.totalEvaluations }}</h1>
							<p class="m-0">{{ "evaluation.totals" | translate }}</p>
						</div>
					</div>
					<div
						*ngIf="isSelfPage && showTeamButton && project.name == 'leroymerlin' && !mainPeriod?.isActive"
						fxLayout="row"
						class="evaluation-panel mt-24"
					>
						<button mat-raised-button class="btn-feedbalia w-100-p" (click)="goToColaboradoresActuales()">
							Puntuaciones última EDT de mi equipo
						</button>
						<mat-icon class="btn-help ml-8" (click)="openDialogInfo('sied-futuro')">help</mat-icon>
					</div>
				</ng-container>

				<ng-container *ngIf="project?.name != 'leroymerlin'">
					<div *ngIf="evaluationPanel?.showPanelEvaluations" fxLayout="row" class="evaluation-panel mt-24">
						<div fxFlex="33" class="p-16 pendings" (click)="openPanelControl(0)">
							<h1 class="m-0">{{ evaluationPanel.totalPendings }}</h1>
							<p class="m-0">{{ "evaluation.pendings" | translate }}</p>
						</div>
						<div fxFlex="33" class="p-16 sended" (click)="openPanelControl(1)">
							<h1 class="m-0">{{ evaluationPanel.totalDone }}</h1>
							<p class="m-0">{{ "evaluation.sendings" | translate }}</p>
						</div>
						<div fxFlex="33" class="p-16 totals" (click)="openPanelControl(2)">
							<h1 class="m-0">{{ evaluationPanel.totalEvaluations }}</h1>
							<p class="m-0">{{ "evaluation.totals" | translate }}</p>
						</div>
					</div>
					<div *ngIf="evaluationPanel?.showPanelEvaluations && project?.name == 'syrsa'" fxLayout="row" class="evaluation-panel mt-24">
						<button mat-raised-button class="btn-feedbalia w-100-p" (click)="goToColaboradoresActuales()">
							Puntuaciones de mi equipo en periodos anterirores
						</button>
					</div>
				</ng-container>
			</div>

			<!-- Cuestionario manager -->
			<div
				*ngIf="user.id === person.id && mainPeriod?.isActive && mainPeriod?.isEnabledEvaluationEvaluators && evaluatorsToEvaluate"
				@onRenderUp
				class="fuse-card card-border-top card-sied"
			>
				<h4 class="text-center text-theme m-8" style="font-weight: bold; font-size: 14px">Cuestionario de evaluación al manager</h4>
				<div
					*ngFor="let evaluator of evaluatorsToEvaluate"
					fxLayout="row wrap"
					fxLayoutAlign="space-between center"
					class="evaluation-evaluators-container m-24 p-24"
				>
					<div fxLayout="row wrap" fxLayoutAlign="center center">
						<div class="person-avatar-xs mr-16">
							<img class="img-circle" [src]="getImagePerson(evaluator.nickname)" [alt]="evaluator.displayName" [title]="evaluator.displayName" />
						</div>
						<span style="font-weight: bold">{{ evaluator.displayName }}</span>
					</div>
					<button class="action-button pointer" (click)="goToManagerEvaluation(evaluator)">Evaluar ahora</button>
				</div>
			</div>

			<!-- Reunión manager -->
			<div
				*ngIf="user.id === person.id && mainPeriod?.isActive && mainPeriod?.isEnabledCheckQuestion && evaluation"
				@onRenderUp
				class="evaluation-question-container mb-12 p-24"
			>
				<h3 class="text-center" style="font-size: 16px">
					¿Has tenido la entrevista de talento con tu manager para hablar de los resultados de la evaluación?
				</h3>
				<div *ngIf="evaluation" fxLayout="row wrap" fxLayoutAlign="center center">
					<button class="action-button pointer mr-16" [ngClass]="evaluation.checkByUser ? 'enabled' : 'disabled'" (click)="setCheckByUser(true)">
						Sí
					</button>
					<button class="action-button pointer" [ngClass]="!evaluation.checkByUser ? 'enabled' : 'disabled'" (click)="setCheckByUser(false)">
						No
					</button>
				</div>
			</div>

			<!-- Feedback privado -->
			<div
				*ngIf="(privateFeedbackRequestList || privateFeedbackResponseList || privateFeedbackHistory) && user.id === person.id"
				@onRenderUp
				class="fuse-card card-border-top card-sied card-historico"
			>
				<mat-tab-group mat-align-tabs="center">
					<!-- Solicitados -->
					<mat-tab *ngIf="privateFeedbackRequestList" class="text-theme" label="Feedback privado solicitado">
						<div class="p-8" fxLayout="row wrap" fxLayoutAlign="center center">
							<div
								*ngFor="let itemFeed of privateFeedbackRequestList"
								class="card-private-feedback p-8 m-8"
								fxLayout="column"
								fxLayoutAlign="center center"
							>
								<div class="person-avatar">
									<img
										class="img-circle"
										[src]="getImagePerson(itemFeed.personAboutNickname)"
										[alt]="itemFeed.personAboutName"
										[title]="itemFeed.personAboutName"
									/>
								</div>
								<p class="m-0 font-size-12 text-center">Sobre: {{ itemFeed.personAboutName }}</p>
								<mat-icon>arrow_upward</mat-icon>
								<div class="person-avatar-xs">
									<img
										class="img-circle"
										[src]="getImagePerson(itemFeed.personResponseNickname)"
										[alt]="itemFeed.personResponseName"
										[title]="itemFeed.personResponseName"
									/>
								</div>
								<p class="m-0 font-size-10 text-center">Para: {{ itemFeed.personResponseName }}</p>
								<p *ngIf="!itemFeed.response" class="m-0 font-size-14 blue-font">
									<strong>Pendiente</strong>
								</p>
								<p
									*ngIf="itemFeed.response"
									class="m-0 font-size-14 pointer"
									style="color: green"
									(click)="openFeedbackDialog(mainPeriod.name, itemFeed, false)"
								>
									<strong>Ver respuesta</strong>
								</p>
							</div>
						</div>
					</mat-tab>
					<!-- Peticiones -->
					<mat-tab *ngIf="privateFeedbackResponseList" class="text-theme" label="Peticiones feedback privado">
						<div class="p-8" fxLayout="row wrap" fxLayoutAlign="center center">
							<div
								*ngFor="let itemFeed of privateFeedbackResponseList"
								class="card-private-feedback p-8 m-8"
								fxLayout="column"
								fxLayoutAlign="center center"
							>
								<div class="person-avatar">
									<img
										class="img-circle"
										[src]="getImagePerson(itemFeed.personAboutNickname)"
										[alt]="itemFeed.personAboutName"
										[title]="itemFeed.personAboutName"
									/>
								</div>
								<p class="m-0 font-size-12 text-center">Sobre: {{ itemFeed.personAboutName }}</p>
								<mat-icon>arrow_upward</mat-icon>
								<div class="person-avatar-xs">
									<img
										class="img-circle"
										[src]="getImagePerson(itemFeed.personRequestNickname)"
										[alt]="itemFeed.personRequestName"
										[title]="itemFeed.personRequestName"
									/>
								</div>
								<p class="m-0 font-size-10 text-center">De: {{ itemFeed.personRequestName }}</p>
								<p *ngIf="!itemFeed.response" class="m-0 font-size-14 blue-font pointer" (click)="openFeedbackDialog(null, itemFeed, true)">
									<strong>Responder</strong>
								</p>
								<p
									*ngIf="itemFeed.response"
									class="m-0 font-size-14 pointer"
									style="color: green"
									(click)="openFeedbackDialog(mainPeriod.name, itemFeed, false)"
								>
									<strong>Ver respuesta</strong>
								</p>
							</div>
						</div>
					</mat-tab>
					<!-- Historico -->
					<mat-tab *ngIf="privateFeedbackHistory" class="text-theme" label="Histórico feedback privado">
						<div class="p-8">
							<div *ngFor="let itemPeriod of privateFeedbackHistory" fxLayout="column">
								<div class="pointer" fxLayout="row" fxLayoutAlign="space-between center" (click)="tooglePeriod(itemPeriod)">
									<h5 class="m-8 font-size-16 blue-font">
										<strong>{{ itemPeriod.name }}</strong>
									</h5>
									<mat-icon class="s-18">{{ !itemPeriod.extended ? "keyboard_arrow_down" : "keyboard_arrow_up" }}</mat-icon>
								</div>
								<div *ngFor="let itemFeed of itemPeriod.feedbackRequest">
									<div *ngIf="itemPeriod.extended" fxLayout="column" class="p-8 mb-8 feedback-history">
										<strong class="m-0 font-size-14">{{ formatDateString(itemFeed.creationDate) }}</strong>
										<div fxLayout="row" fxLayoutAlign="left center">
											<div fxFlex="30" fxLayout="column">
												<h5 class="m-0 font-size-14">Solicitud de</h5>
												<h5 class="m-0 font-size-12 blue-font">{{ user.displayName }}</h5>
											</div>
											<div fxFlex="30" fxLayout="column">
												<h5 class="m-0 font-size-14">Para</h5>
												<h5 class="m-0 font-size-12 blue-font">{{ itemFeed.personResponseName }}</h5>
											</div>
											<div fxFlex="30" fxLayout="column">
												<h5 class="m-0 font-size-14">Sobre</h5>
												<h5 class="m-0 font-size-12 blue-font">{{ itemFeed.personAboutName }}</h5>
											</div>
											<div fxFlex="10" class="px-8 pointer">
												<mat-icon (click)="openFeedbackDialog(itemPeriod.name, itemFeed, false)">visibility</mat-icon>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</mat-tab>
				</mat-tab-group>
			</div>

			<!-- Historicos -->
			<div class="fuse-card card-border-top card-sied card-historico" @onRenderUp>
				<mat-tab-group mat-align-tabs="center" (selectedTabChange)="historyTabChanged($event)">
					<!-- Evaluaciones -->
					<mat-tab class="text-theme" label="{{ 'evaluation.sied.historico' | translate }}">
						<mat-icon class="btn-help" (click)="openDialogInfo('historico')">help</mat-icon>
						<div *ngIf="history">
							<div class="listado p-8">
								<div *ngFor="let itemEval of history" class="evaluacion p-8 mb-8" fxLayout="row" [ngClass]="project?.id == 472 ? 'sinFondo' : ''">
									<div fxFlex="20" class="px-8">
										<h5 *ngIf="itemEval.es_cualitativa == 0" class="m-0 font-size-14">
											<b>{{ itemEval.totalResult.toFixed(2) }}</b>
										</h5>
										<h5 *ngIf="itemEval.es_cualitativa == 1" class="m-0 font-size-12"><b>Evalución cualitativa</b></h5>
									</div>
									<div fxFlex="70" fxLayout="column" class="px-8">
										<h5 class="m-0 font-size-14">{{ itemEval.periodName }}</h5>
										<h6 class="m-0 font-size-10">{{ itemEval.puestoName }}</h6>
									</div>
									<div fxFlex="30" class="px-8">
										<h6 class="m-0 pointer">
											<a class="text-muted" (click)="goToEvaluationForm(itemEval)">{{ "evaluation.btn.see" | translate }}</a>
										</h6>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="!history">
							<div class="listado p-8">
								{{ "evaluation.no.elements" | translate }}
							</div>
						</div>
					</mat-tab>
					<!-- Cuestionarios -->
					<mat-tab class="text-theme" label="Histórico de cuestionarios" *ngIf="mainPeriod?.isEnabledEvaluationEvaluators">
						<div *ngIf="history">
							<div class="listado p-8">
								<div *ngFor="let itemEval of history" class="evaluacion p-8 mb-8" fxLayout="row">
									<div fxFlex="20" class="px-8"></div>
									<div fxFlex="70" fxLayout="column" class="px-8">
										<h5 class="m-0 font-size-14">{{ itemEval.periodName }}</h5>
										<h6 class="m-0 font-size-10">{{ itemEval.puestoName }}</h6>
									</div>
									<div fxFlex="30" class="px-8">
										<h6 class="m-0 pointer">
											<a class="text-muted" (click)="goToEvaluationForm(itemEval)">{{ "person_card.sied.showScore" | translate }}</a>
										</h6>
									</div>
								</div>
							</div>
						</div>
						<div *ngIf="!history">
							<div class="listado p-8">
								{{ "evaluation.no.elements" | translate }}
							</div>
						</div>
					</mat-tab>
				</mat-tab-group>
			</div>
		</div>
		}

		<div fxLayout="column" fxFlex="60" class="evaluation-container px-16" @onRenderUp>
			@if(permisosPDA.r){
			<div fxLayout="column" class="fuse-card card-border-top card-sied card-planes-accion p-24 gap-1">
				<mat-icon class="btn-help" (click)="openDialogInfo('plan-accion')">help</mat-icon>

				<div fxLayout="row">
					<div fxFlex="60" fxLayout="column">
						<h5 class="m-0 font-size-14 text-theme">{{ "evaluation.actionplan" | translate | uppercase }}</h5>
						<h6 class="m-0 font-size-12">{{ "evaluation.complete.before" | translate }}</h6>
					</div>

					<div fxFlex="40" fxLayout="row" fxLayoutAlign="space-around center">
						<div fxLayout="column" class="text-center gap-1">
							<div class="accionPend gap-1">
								<mat-icon>access_time</mat-icon>
								<span class="font-size-18">{{ totalActionPending }}</span>
							</div>

							<h6 class="m-0 text-theme font-size-8">{{ "evaluation.value.pending" | translate | uppercase }}</h6>
						</div>

						<div fxLayout="column" class="text-center gap-1">
							<div class="accionComp gap-1">
								<mat-icon>check_box</mat-icon>
								<span class="font-size-18">{{ totalActionDone }}</span>
							</div>

							<h6 class="m-0 text-theme font-size-8">{{ "evaluation.value.completed" | translate | uppercase }}</h6>
						</div>
					</div>
				</div>

				<ng-container *ngIf="project?.name != 'syrsa'">
					<div fxLayout="column" class="gap-1">
						@if(permisosPDA.w){
						<div fxLayout="row" fxLayoutAlign="center center" class="gap-1" *ngIf="project?.id != 10">
							<mat-form-field subscriptSizing="dynamic" appearance="outline" class="w-100-p">
								<mat-label>{{ "evaluation.actionplan.placeholder.add.title" | translate }}</mat-label>
								<input
									matInput
									class="gap-1"
									(focus)="showHelpBoxActionPlan = true"
									(blur)="showHelpBoxActionPlan = false"
									(change)="onActionPlanTitle($event)"
								/>
							</mat-form-field>

							<button mat-raised-button class="btn-feedbalia" [disabled]="!canCreatePlanAction()" (click)="addPA()">
								<mat-icon>add_circle</mat-icon>
								{{ "evaluation.actionplan.next.add" | translate }}
							</button>
						</div>
						}

						<div *ngIf="showHelpBoxActionPlan" fxLayout="row" class="alert alert-info">
							<span>{{ "evaluation.actionplan.help" | translate }}</span>
						</div>
					</div>

					<mat-divider />

					<form fxLayout="row" name="form" [formGroup]="formFiltroPlanAccion">
						<mat-form-field subscriptSizing="dynamic" appearance="outline" class="w-100-p">
							<mat-label>{{ "evaluation.actionplan.placeholder.search.title" | translate }}</mat-label>
							<input matInput #titleFilter (keyup)="filterPATitle(titleFilter)" />
						</mat-form-field>
					</form>

					<div class="gap-1" fxLayout="row">
						<mat-form-field subscriptSizing="dynamic" appearance="outline" class="w-100-p">
							<mat-label>{{ "evaluation.actionplan.filter.status" | translate }}</mat-label>
							<mat-select [(ngModel)]="filterPAForm.status">
								<mat-option [value]="'100'">{{ "evaluation.actionplan.filter.completed" | translate }}</mat-option>
								<mat-option [value]="'50'">{{ "evaluation.actionplan.filter.progress" | translate }}</mat-option>
								<mat-option [value]="'0'">{{ "evaluation.actionplan.filter.no.completed" | translate }}</mat-option>
								<mat-option [value]="'NA'">{{ "evaluation.actionplan.filter.na" | translate }}</mat-option>
							</mat-select>
						</mat-form-field>

						<mat-form-field subscriptSizing="dynamic" *ngIf="createdByUsers.length" appearance="outline" class="w-100-p">
							<mat-label>{{ "evaluation.actionplan.filter.createdby" | translate }}</mat-label>
							<mat-select [(ngModel)]="filterPAForm.createdBy">
								<mat-option *ngFor="let user of createdByUsers" [value]="user.id">{{ user.name }}</mat-option>
							</mat-select>
						</mat-form-field>

						<div class="filter-date">
							<input
								matInput
								ngxDaterangepickerMd
								[(ngModel)]="filterPAForm.inicio"
								name="inicio"
								placeholder="{{ 'general.start_date' | translate }}"
								[locale]="localeDateRange"
								showClearButton="true"
								alwaysShowCalendars="true"
								keepCalendarOpeningWithRange="true"
								opens="left"
							/>
						</div>

						<div class="filter-date">
							<input
								matInput
								ngxDaterangepickerMd
								[(ngModel)]="filterPAForm.fin"
								name="fin"
								placeholder="{{ 'general.end_date' | translate }}"
								[locale]="localeDateRange"
								showClearButton="true"
								alwaysShowCalendars="true"
								keepCalendarOpeningWithRange="true"
								opens="left"
							/>
						</div>
					</div>

					<div class="gap-1" fxLayout="row" fxLayoutAlign="center center">
						<button mat-raised-button class="btn-feedbalia" (click)="clearFilterPA()">
							{{ "general.button.clear" | translate }}
						</button>
						<button mat-raised-button class="btn-feedbalia" (click)="filterPA()">
							{{ "evaluation.filter.btn" | translate }}
						</button>
					</div>
				</ng-container>

				<div *ngIf="project?.name == 'syrsa'" fxLayout="column" style="height: 20px"></div>

				<div class="container-plan-accion" *ngIf="mainPeriod">
					<div *ngIf="!filteredActionPlans" fxLayout="row" fxLayoutAlign="center" class="spinner">
						<mat-spinner color="accent"></mat-spinner>
					</div>
					<div *ngIf="filteredActionPlans" @slideInRightList>
						<div *ngFor="let itemPA of filteredActionPlans">
							<action-plan
								[datos-plan-accion]="itemPA"
								[revisionPeriodEnabled]="paRevisionPeriod ? true : false"
								[evpartial-id]="evPartial ? evPartial.id : null"
								[period-id]="null"
								[user]="user"
								[person]="person"
								[editable]="canEditPlanAction(itemPA)"
								[read-only]="false"
								[startPeriod]="mainPeriod.startPeriod"
								[inEvaluation]="false"
								[canBeBlocked]="canBlockPDAs()"
								[permisos]="permisosPDA"
								(reload)="getActionsPlans()"
								(onChangeValue)="onChangeElementValue($event)"
							>
							</action-plan>
						</div>
					</div>
				</div>
			</div>
			}
		</div>
	</div>
</div>
}
