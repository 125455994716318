<div id="private-feedback-dialog" class="p-12">
	<!-- Solicitar feedback privado -->
	<div *ngIf="!data.privateFeedback">
		<h1 class="m-0 mb-12">Solicitar feedback privado</h1>
		<div class="fullwidth" style="position: relative; margin-top: 5px">
			<mat-form-field subscriptSizing="dynamic" class="search-user w-95-p">
				<input
					matInput
					placeholder="Busca a la persona que quieres pedirle feedback privado..."
					[(ngModel)]="filterPersonValue"
					(keyup)="filterPersons($event)"
				/>
				<mat-icon *ngIf="!personSelected" matSuffix class="s-18">search</mat-icon>
				<button mat-button *ngIf="filterPersonValue.length > 0" matSuffix mat-icon-button aria-label="Clear" (click)="clearPerson()">
					<mat-icon>close</mat-icon>
				</button>
			</mat-form-field>

			<mat-error *ngIf="errorMsg">{{ errorMsg }}</mat-error>

			<div *ngIf="persons.length > 0 && !personSelected" class="wrap-persons">
				<div class="list-persons">
					<div *ngFor="let person of persons" fxlayout="row" fxLayoutAlign="flex-start center" class="item-person" (click)="selectPerson(person)">
						<div class="person-avatar avatar-50 mr-8" fxLayout="row" fxLayoutAlign="center center">
							<img class="img-circle" [src]="getImagePerson(person.username)" [title]="person.username" />
						</div>
						<div fxLayout="column" class="info-person">
							<span class="m-0">{{ "@" + person.username }}</span>
							<span class="m-0"
								><strong>{{ person.displayName }}</strong></span
							>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div fxLayout="row wrap" fxLayoutAlign="end center">
			<button
				class="action-button"
				mat-button
				aria-label="Solicitar"
				[ngClass]="!personSelected ? 'disabled' : null"
				[disabled]="!personSelected"
				(click)="solicitarFeedback()"
			>
				Solicitar feedback
			</button>
		</div>
	</div>

	<!-- Feedback privado solicitado -->
	<div *ngIf="data.privateFeedback && !data.isRequest && !data.privateFeedback.response">
		<h1 class="m-0 mb-12">Feedback privado solicitado</h1>
		<p>
			Esperando respuesta. Recuerda que tienes un número límitado de feedback privado a solicitar. Si lo deseas puedes eliminar una petición que aún
			no tenga respuesta.
		</p>

		<div fxLayout="row wrap" fxLayoutAlign="end center">
			<button class="action-button" mat-button aria-label="Remove" (click)="removeSolicitud()">Eliminar solicitud</button>
		</div>
	</div>

	<!-- Feedback privado con respuesta -->
	<div *ngIf="data.privateFeedback && data.privateFeedback.response">
		<h1 class="m-0 mb-12">Respuesta de feedback privado</h1>
		<div fxLayout="column">
			<div fxLayout="row" class="pb-12">
				<div fxFlex="25" fxLayout="column">
					<h5 class="m-0 font-size-14"><strong>Periodo</strong></h5>
					<h5 class="m-0 font-size-12 blue-font">{{ data.periodName }}</h5>
				</div>
				<div fxFlex="25" fxLayout="column">
					<h5 class="m-0 font-size-14"><strong>Solicitud de</strong></h5>
					<h5 class="m-0 font-size-12 blue-font">{{ data.privateFeedback.personRequestName }}</h5>
				</div>
				<div fxFlex="25" fxLayout="column">
					<h5 class="m-0 font-size-14"><strong>Para</strong></h5>
					<h5 class="m-0 font-size-12 blue-font">{{ data.privateFeedback.personResponseName }}</h5>
				</div>
				<div fxFlex="25" class="px-8 pointer">
					<h5 class="m-0 font-size-14"><strong>Sobre</strong></h5>
					<h5 class="m-0 font-size-12 blue-font">{{ data.privateFeedback.personAboutName }}</h5>
				</div>
			</div>
			<div fxLayout="column" class="pb-12">
				<p class="m-0"><strong>Respuesta</strong></p>
				<textarea class="m-0 response" readonly>{{ data.privateFeedback.response }}</textarea>
			</div>
			<div fxLayout="row" class="pb-12">
				<div fxLayout="column" class="mr-24">
					<p class="m-0"><strong>Fecha solicitud</strong></p>
					<p class="m-0">{{ formatDate(data.privateFeedback.creationDate) }}</p>
				</div>
				<div fxLayout="column" class="mr-24">
					<p class="m-0"><strong>Fecha respuesta</strong></p>
					<p class="m-0">{{ formatDate(data.privateFeedback.responseDate) }}</p>
				</div>
			</div>
		</div>
		<div fxLayout="row wrap" fxLayoutAlign="end center">
			<button class="action-button" mat-button aria-label="Close" (click)="close()">Cerrar</button>
		</div>
	</div>

	<!-- Solicitud de feedback privado -->
	<div *ngIf="data.isRequest">
		<h1 class="m-0 mb-12">Solicitud de feedback privado</h1>
		<p>
			El usuario <strong>{{ data.privateFeedback.personRequestName }}</strong> te ha solicitado feedback privado sobre
			<strong>{{ data.privateFeedback.personAboutName }}</strong
			>.
		</p>
		<p>
			Este feedback es privado, únicamente será visible por usted y por la persona que se lo ha solicitado, la persona evaluada no tendrá acceso a
			esta información
		</p>
		<p>A continuación puedes dar feedback sobre el usuario</p>
		<div fxLayout="column">
			<div fxLayout="column" class="pb-12">
				<p class="m-0"><strong>Respuesta</strong></p>
				<textarea class="m-0 response" (keyup)="setResponse($event)"></textarea>
			</div>
			<mat-error *ngIf="errorMsg">{{ errorMsg }}</mat-error>
			<div fxLayout="row" class="pb-12">
				<div fxLayout="column" class="mr-24">
					<p class="m-0"><strong>Fecha solicitud</strong></p>
					<p class="m-0">{{ formatDate(data.privateFeedback.creationDate) }}</p>
				</div>
			</div>
		</div>
		<div fxLayout="row wrap" fxLayoutAlign="end center">
			<button class="action-button" mat-button aria-label="Close" (click)="sendResponse()">Responder</button>
		</div>
	</div>
</div>
