<div
	id="colaborator-card"
	*ngIf="period"
	fxLayout="column"
	fxLayoutAlign="center center"
	[ngStyle]="{ 'height.px': projectName === 'syrsa' ? 'auto' : 'auto' }"
>
	<!-- Tranfer, Fedback -->
	<div *ngIf="!checkCurrent" class="transfer">
		<mat-icon *ngIf="evaluation.type === 'TRANSFER'" class="green" [title]="'Cedido por ' + originalEvaluator">arrow_forward</mat-icon>
		<mat-icon *ngIf="transferedTo" class="pointer red" [title]="'Cedido a ' + transferedTo.newEvaluatorName" (click)="openTransferDialog()"
			>arrow_back</mat-icon
		>
		<mat-icon
			*ngFor="let pf of privateFeedbackList"
			class="pointer"
			[title]="'Feedback privado solicitado a ' + pf.personResponseName"
			(click)="openFeedbackDialog(pf)"
			>feedback</mat-icon
		>
	</div>

	<div
		*ngIf="period.isActive && (period.isEnabledEvaluationTransfer || period.isEnabledPrivateFeedback) && evaluation.personId != userId"
		class="transfer-menu"
	>
		<button mat-icon-button [matMenuTriggerFor]="colaboratorMenu" class="quick-panel-toggle-button menu-button">
			<mat-icon>menu</mat-icon>
		</button>
	</div>

	<mat-menu #colaboratorMenu="matMenu" [overlapTrigger]="false">
		<button *ngIf="period.isEnabledEvaluationTransfer" mat-menu-item (click)="openTransferDialog()">
			<mat-icon class="m-0">arrow_back</mat-icon>
			Cesión de evaluación
		</button>
		<button *ngIf="period.isEnabledPrivateFeedback" mat-menu-item (click)="openFeedbackDialog()">
			<mat-icon class="m-0">feedback</mat-icon>
			Solicitar feedback privado
		</button>
		@if(projectName.includes('iberext')){
		<button mat-menu-item (click)="addNota()">
			<mat-icon class="m-0">assignment</mat-icon>
			Ver notas
		</button>
		}
	</mat-menu>

	<div class="autoev-box" *ngIf="evaluation.personId == userId" fxLayout="row" fxLayoutAlign="center flex-start">Autoevaluación</div>

	<div class="person-avatar" fxLayout="row" fxLayoutAlign="center flex-start">
		<img class="img-circle" [src]="getImagePerson(evaluation.nickname)" [alt]="evaluation.firstName" />
	</div>

	<h5 class="name-user">
		<strong>{{ evaluation.displayName }}</strong>
	</h5>
	<h5 class="puesto-user">{{ evaluation.puesto?.puesto }}</h5>
	<p>
		<a class="pointer" (click)="goToDashboard(evaluation.personId)">{{ "evaluation.filter.btn.dashboard" | translate }}</a>
	</p>

	<!-- Planes de accion -->
	<p>{{ "evaluation.controlpanel.actionsplans" | translate }} {{ evaluation.actionPlans }}</p>
	<div *ngIf="period.isAtivedPeriodRevision && userId != evaluation.personId" fxLayout="column" fxLayoutAlign="center center">
		<button *ngIf="actionPlansPendingsRevision === 0" class="action-button btn-success pointer" (click)="goToDashboard(evaluation.personId)">
			Revisados
		</button>
		<button *ngIf="actionPlansPendingsRevision > 0" class="action-button btn-danger pointer" (click)="goToDashboard(evaluation.personId)">
			{{ actionPlansPendingsRevision }} {{ "evaluation.controlpanel.actionsplans.pendings" | translate }}
		</button>
	</div>
	<ng-container *ngIf="projectName == 'syrsa'">
		<p *ngIf="isBorradorCompletado && isDraftByEvaluator" style="color: green">Borrador guardado como completado</p>
	</ng-container>

	<p *ngIf="period.isEnabledPrivateFeedback">Feedback Privado Disponible: {{ privateFeedbackCount }}</p>

	<!-- Autoevalucion -->
	<div *ngIf="evaluation.hasAutoevaluation">
		<div *ngIf="evaluation.isAutoevaluationPending" class="eval-pending" fxLayoutAlign="center center">
			<span>{{ "evaluation.filter.card.autopending" | translate }}</span>
			<mat-icon class="pointer" (click)="sendUserPendingEvaluationMail(evaluation.personId)" title="Avisar de autoevaluación">notifications</mat-icon>
		</div>
		<div *ngIf="!evaluation.isAutoevaluationPending" class="eval-done" fxLayoutAlign="center center">
			<span>{{ "evaluation.filter.card.autocompleted" | translate }}</span>
		</div>
	</div>

	<button
		class="action-button pointer"
		[disabled]="!checkCurrent && transferedTo"
		[ngClass]="!checkCurrent && transferedTo ? 'disabled' : ''"
		*ngIf="!lessThan6months && projectName != 'syrsa'"
		(click)="goToEvaluationForm()"
	>
		{{ getEvaluationStatusButton() }}
	</button>
	<button
		class="action-button pointer"
		[disabled]="(!checkCurrent && transferedTo) || !aspectosOk"
		[ngClass]="(!checkCurrent && transferedTo) || !aspectosOk ? 'disabled' : ''"
		*ngIf="!lessThan6months && projectName == 'syrsa'"
		(click)="goToEvaluationForm()"
	>
		{{ getEvaluationStatusButton() }}
	</button>
	<h3 *ngIf="lessThan6months"><b>No aplica</b></h3>

	@if(showNotDone){
	<div fxLayout="row" fxLayoutAlign="center" class="mt-8 resumen">
		<div fxLayoutAlign="center" fxFlex="50" title="Nota total">
			<fa-icon class="mr-4" icon="file-alt"></fa-icon>
			<span style="font-size: 14px">No evaluado</span>
		</div>
	</div>
	}@else{
	<div fxLayout="row" fxLayoutAlign="center" class="mt-8 resumen">
		<div *ngIf="period.es_cualitativa == 0" fxLayoutAlign="center" fxFlex="50" title="Nota total">
			<fa-icon class="mr-4" icon="file-alt"></fa-icon>
			@if(checkCurrent){ @if(evaluation.result){
			<span>{{ roundTwoDecimals(evaluation.result) }} / 100</span>
			}@else {
			<span>- / 100</span>
			} }@else{
			<span *ngIf="isDoneByEvaluator">{{ roundTwoDecimals(evaluation.result) }} / 100</span>
			<span *ngIf="!isDoneByEvaluator && isDraftByEvaluator"> {{ roundTwoDecimals(partial?.result) }} / 100</span>
			<span *ngIf="!isDoneByEvaluator && !isDraftByEvaluator"> - / 100</span>
			}
		</div>
		<div *ngIf="period.es_cualitativa == 1" fxLayoutAlign="center" fxFlex="70" title="Nota total" class="font-size-14">
			<span>Evaluación cualitativa</span>
		</div>
		<div *ngIf="period.isEvaluatedCompetenciesBadges" fxLayoutAlign="center" fxFlex="50" title="Nota medallas">
			<fa-icon class="mr-4" icon="medal"></fa-icon>
			<span>- / 100</span>
		</div>
	</div>
	}

	<!-- Aspects -->
	<div
		*ngIf="period.isEnabledEvaluationAspect"
		fxLayout="row wrap"
		fxLayoutAlign="space-between center"
		class="aspects mt-8"
		style="margin-bottom: 5px"
	>
		<ng-container *ngFor="let aspect of aspectsList">
			<div fxLayout="column" fxLayoutAlign="center center" [ngClass]="aspect.class" *ngIf="projectName != 'syrsa'">
				<div fxLayout="row">
					<mat-label>{{ aspect.name }}</mat-label>
					<mat-icon *ngIf="aspect.helpText" [title]="aspect.helpText" class="help">help</mat-icon>
				</div>
				<!-- Select -->
				<mat-form-field subscriptSizing="dynamic" *ngIf="aspect.type === 'SELECT'" dense appearance="outline" class="select">
					<input matInput style="display: none" />
					<mat-select
						[id]="evaluation.personId + '-' + aspect.id"
						class="form-control"
						[value]="getAspectValue(evaluation.aspects, aspect.id)"
						[disabled]="!period.isActive || (lessThan6months && projectName == 'syrsa')"
					>
						<mat-option value="" (click)="onSelectAspect(aspect.id, '', evaluation.personId)">-</mat-option>
						<mat-option *ngFor="let value of aspect.values" [value]="value.id" (click)="onSelectAspect(aspect.id, value.id)">{{
							value.name
						}}</mat-option>
					</mat-select>
				</mat-form-field>
				<!-- Potencial -->
				<div *ngIf="aspect.type === 'EVALUATION_POTENCIAL'" class="potencial">
					<div>{{ evaluation.potencial }} / 100</div>
				</div>
				<!-- Person -->
				<div *ngIf="aspect.type === 'PERSON'" class="person">
					<div class="fullwidth" style="position: relative; margin-top: 5px">
						<mat-form-field subscriptSizing="dynamic" [id]="evaluation.personId + '-' + aspect.id" class="search-user w-95-p">
							<input
								matInput
								placeholder="Buscar persona"
								[(ngModel)]="filterPersonValue"
								(keyup)="filterPersons($event)"
								[disabled]="!period.isActive || (lessThan6months && projectName == 'syrsa')"
							/>
							<mat-icon *ngIf="!personSelected" matSuffix class="s-18">search</mat-icon>
							<button
								mat-button
								*ngIf="filterPersonValue.length > 0 && period.isActive"
								matSuffix
								mat-icon-button
								aria-label="Clear"
								(click)="clearPerson(aspect.id)"
							>
								<mat-icon>close</mat-icon>
							</button>
						</mat-form-field>

						<div *ngIf="persons.length > 0 && !personSelected" class="wrap-persons">
							<div class="list-persons">
								<div
									*ngFor="let person of persons"
									fxlayout="row"
									fxLayoutAlign="flex-start center"
									class="item-person"
									(click)="selectPerson(aspect.id, person)"
								>
									<div class="person-avatar avatar-50 mr-8" fxLayout="row" fxLayoutAlign="center center">
										<img class="img-circle" [src]="getImagePerson(person.username)" [title]="person.username" />
									</div>
									<div fxLayout="column" class="info-person">
										<span class="m-0">{{ "@" + person.username }}</span>
										<span class="m-0"
											><strong>{{ person.displayName }}</strong></span
										>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div fxLayout="column" fxLayoutAlign="center center" [ngClass]="aspect.class" *ngIf="projectName == 'syrsa'">
				<div
					fxLayout="column"
					*ngIf="aspect.id == 13 || aspect.id == 10"
					style="border-bottom: 1px solid rgb(61, 61, 61); width: 100%; height: 15px"
				></div>
				<div fxLayout="column" *ngIf="aspect.id == 13 || aspect.id == 10" style="width: 100%; height: 14px"></div>

				<div
					fxLayout="column"
					fxLayoutAlign="center center"
					[ngClass]="aspect.class"
					*ngIf="
						projectName == 'syrsa' &&
						(aspect.id == 13 || aspect.id == 10 || (aspect.id == 11 && arrayAspectos[1] == 1) || (aspect.id == 12 && arrayAspectos[2] == 1))
					"
					[ngStyle]="{ 'background-color': colorMap[aspect.id] || 'transparent' }"
				>
					<div fxLayout="row" *ngIf="aspect.id == 10" style="padding-top: 5px">
						<mat-label>PLAN DE SUCESIÓN</mat-label>
					</div>
					<div fxLayout="row" *ngIf="aspect.id == 10" style="padding-top: 5px">
						<mat-label>Desde tu punto de vista...</mat-label>
					</div>

					<div fxLayout="row">
						<mat-label [ngStyle]="{ 'padding-top': aspect.id != 10 ? '5px' : '0px' }">{{ aspect.name }}</mat-label>
						<mat-icon *ngIf="aspect.helpText" [title]="aspect.helpText" class="help">help</mat-icon>
					</div>
					<!-- Select -->
					<div *ngIf="aspect.type === 'SELECT'" style="width: 90%; margin-bottom: 5px">
						<input matInput style="display: none" />
						<mat-select
							[id]="evaluation.personId + '-' + aspect.id"
							class="form-control"
							[value]="getAspectValue(evaluation.aspects, aspect.id)"
							[disabled]="!period.isActive || (lessThan6months && projectName == 'syrsa')"
						>
							<mat-option value="" (click)="onSelectAspect(aspect.id, '', evaluation.personId)">-</mat-option>
							<mat-option *ngFor="let value of aspect.values" [value]="value.id" (click)="onSelectAspect(aspect.id, value.id)">{{
								value.name
							}}</mat-option>
						</mat-select>
					</div>
					<!-- Potencial -->
					<div *ngIf="aspect.type === 'EVALUATION_POTENCIAL'" class="potencial">
						<div>{{ evaluation.potencial }} / 100</div>
					</div>
					<!-- Person -->
					<div *ngIf="aspect.type === 'PERSON'" class="person">
						<div class="fullwidth" style="position: relative; margin-top: 5px">
							<mat-form-field
								subscriptSizing="dynamic"
								[id]="evaluation.personId + '-' + aspect.id"
								class="search-user w-100-p"
								style="max-height: 40px !important; margin-bottom: 3px"
							>
								<input
									matInput
									placeholder="Buscar persona"
									[(ngModel)]="filterPersonValue"
									(keyup)="filterPersons($event)"
									[disabled]="!period.isActive || (lessThan6months && projectName == 'syrsa')"
								/>
								<mat-icon *ngIf="!personSelected" matSuffix class="s-18">search</mat-icon>
								<mat-icon *ngIf="personSelected && period.isActive" matSuffix (click)="clearPerson(aspect.id)" class="s-18">close</mat-icon>
							</mat-form-field>

							<div *ngIf="persons.length > 0 && !personSelected" class="wrap-persons">
								<div class="list-persons">
									<div
										*ngFor="let person of persons"
										fxlayout="row"
										fxLayoutAlign="flex-start center"
										class="item-person"
										(click)="selectPerson(aspect.id, person)"
									>
										<div class="person-avatar avatar-50 mr-8" fxLayout="row" fxLayoutAlign="center center">
											<img class="img-circle" [src]="getImagePerson(person.username)" [title]="person.username" />
										</div>
										<div fxLayout="column" class="info-person">
											<span class="m-0">{{ "@" + person.username }}</span>
											<span class="m-0"
												><strong>{{ person.displayName }}</strong></span
											>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</ng-container>
	</div>
</div>
