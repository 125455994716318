import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";
import { feedAnimations } from "app/animations";
import { EvaluationService } from "app/services/evaluation/evaluation.service";
import { OrganigramaService } from "app/services/organigrama/organigrama.service";
import { ProjectService } from "app/services/project/project.service";
import { UserService } from "app/services/user/user.service";
import { User } from "app/shared/models/user.interface";
import moment from "moment";
import { Observable, combineLatest } from "rxjs";
import { map, startWith } from "rxjs/operators";

@Component({
	selector: "app-panel-control",
	templateUrl: "./panel-control.component.html",
	styleUrls: ["./panel-control.component.scss"],
	encapsulation: ViewEncapsulation.None,
	animations: [feedAnimations],
})
export class PanelControlComponent implements OnInit {
	readonly STATUS = ["PENDING", "DONE", "ALL"];

	selectedIndex: number;
	user: User;
	evaluations: Array<any>;
	periodList: Array<any>;
	jobsList: Array<any>;
	aspectsList: Array<any>;

	formControl = new UntypedFormControl();
	departamentList: Array<any>;
	filteredDepartaments: Observable<any[]>;

	selectedPeriod: string;
	selectedDepartament: string = "";
	selectedPuesto: string = "";
	selectedAspect: any;

	currentPeriod: any;
	isBoss = false;

	evaluationsLoaded: boolean = false;
	project: any; //Almacena la configuración del proyecto

	constructor(
		private dialog: MatDialog,
		private route: ActivatedRoute,
		private evaluationSvc: EvaluationService,
		private userSvc: UserService,
		private organigramaSvc: OrganigramaService,
		private projectSvc: ProjectService,
	) {}

	ngOnInit(): void {
		this.route.queryParams.subscribe((params) => {
			this.selectedIndex = params.targetIndex;
			this.userSvc.getDataUser().subscribe((data) => {
				this.user = data;

				// Cargar todos los periodos
				this.evaluationSvc.getEvaluationPeriods("EVALUATION").subscribe((data) => {
					// console.log('periodList', data);
					this.periodList = data;
					// Seleccionar el periodo mas cercano a la fecha actual
					this.selectPeriod(data);
					// Cargar las evaluaciones de último periodo
					this.loadEvaluations();
				});
				// Cargar los departamentos
				this.organigramaSvc.getAllDepartaments().subscribe((data) => {
					// console.log('departamentList', data);
					this.departamentList = data;
					this.initDepartaments();
				});
			});
		});
		this.projectSvc.getProjectConfig().subscribe((data) => {
			this.project = data;
		});
	}

	initDepartaments() {
		this.filteredDepartaments = this.formControl.valueChanges.pipe(
			startWith(""),
			map((value) => this._filterDepartaments(value)),
		);
	}

	loadEvaluations() {
		this.evaluations = [];
		let sortedEvaluations = [];
		this.evaluationsLoaded = false;
		this.loadPeriod().then(() => {
			// Si el periodo no está activo, no hay evaluaciones pendientes
			const _status = this.STATUS[this.selectedIndex];
			if (!(_status === "PENDING" && !this.currentPeriod.isActive)) {
				this.evaluationSvc
					.getAllEvaluationPartial(_status, this.selectedPeriod, this.user.id, this.selectedDepartament, this.selectedPuesto, this.selectedAspect)
					.subscribe((data) => {
						// BlueSpace
						if (this.projectSvc.getProjectId() == "94") {
							this.evaluations = data.filter((ev) => ev.type !== "AUTOEVALUATION");
						} else {
							this.evaluations = data;
						}
						//Comprobamos si es boss
						for (let elem of this.evaluations) {
							if (elem.personId != this.user.id) {
								this.isBoss = true;
								break;
							}
						}

						//Poner la primera la autoevaluacion
						sortedEvaluations = sortedEvaluations.concat(this.evaluations.filter((ev) => ev.personId == this.user.id));
						sortedEvaluations = sortedEvaluations.concat(this.evaluations.filter((ev) => ev.personId != this.user.id));

						// Aplicar filtros
						// if(this.selectedAspect) {
						//         sortedEvaluations = sortedEvaluations.filter(e => {
						//             let foo = false;
						//             for(let aspect in this.selectedAspect) {
						//                 const filterValue = this.selectedAspect[aspect];
						//                 if(filterValue) {
						//                     console.log(e, aspect, filterValue);
						//                     const evalAspectValue = e.aspect.find(a => a.aspectId === aspect);
						//                     if(evalAspectValue === filterValue) {
						//                         foo = true;
						//                     } else {
						//                         foo = false;
						//                     }
						//                 }
						//             }
						//             return foo;
						//         });
						// }

						this.evaluations = sortedEvaluations;
						console.log("Evaluations", this.evaluations);
						this.evaluationsLoaded = true;
					});
			}
		});
	}

	loadPeriod() {
		return new Promise((resolve, reject) => {
			this.evaluationSvc.getEvaluationPeriodById(this.selectedPeriod).subscribe((data) => {
				this.currentPeriod = data;
				// Calcular si es periodo activo o pasado
				this.processPeriodDate();
				console.log("Current Period", this.currentPeriod);
				resolve(true);
				// Aspectos
				if (this.currentPeriod.isEnabledEvaluationAspect) {
					this.evaluationSvc.getEvaluationAspects().subscribe((data) => {
						this.aspectsList = data;
						if (!this.selectedAspect) {
							this.resetAspects();
						}
					});
				}
			});
		});
	}

	processPeriodDate() {
		if (!this.currentPeriod) {
			return;
		}
		const _startDate = moment(this.currentPeriod.startPeriod);
		const _endDate = moment(this.currentPeriod.endPeriod);
		const _now = moment();

		if (_now > _endDate) {
			this.currentPeriod.isActive = false;
			this.currentPeriod.isLast = true;
		} else if (_now.isBetween(_startDate, _endDate) || this.currentPeriod.isEnabledSaveEvaluationBeforePeriod) {
			this.currentPeriod.isActive = true;
			this.currentPeriod.isLast = false;
		} else {
			this.currentPeriod.isActive = false;
			this.currentPeriod.isLast = false;
		}
	}

	onChangeTab(evt) {
		this.selectedIndex = evt.index;
		this.loadEvaluations();
	}

	onChangePeriod(value) {
		this.selectedPeriod = value;
		if (this.currentPeriod.isEnabledEvaluationAspect) {
			this.resetAspects();
		}
	}

	onSelectDepartament() {
		if (this.formControl.value) {
			this.selectedDepartament = this.departamentList.find((dep) => dep.departamento === this.formControl.value).id;
		}
		if (!this.selectedDepartament) {
			this.jobsList = [];
			return;
		}
		this.organigramaSvc.getJob(this.selectedDepartament).subscribe((data) => {
			this.jobsList = data;
			// console.log('jobList', data);
		});
	}

	clearDepartament() {
		this.selectedDepartament = null;
		this.formControl.reset();
		this.initDepartaments();
		this.jobsList = [];
	}

	onChangePuesto(value) {
		this.selectedPuesto = value;
	}

	onChangeAspect(aspectId, value) {
		this.selectedAspect[aspectId] = value;
	}

	applyFilters() {
		console.log("Filtros", this.selectedDepartament, this.selectedPuesto, this.selectedAspect);
		this.loadEvaluations();
	}

	resetAspects() {
		this.selectedAspect = {};
		this.aspectsList.forEach((aspect) => {
			if (aspect.type === "SELECT") {
				this.selectedAspect[aspect.id] = "";
			}
		});
	}

	sendAllPendingsEvaluationsMail() {
		let observables = [];
		this.evaluations.forEach((e) => {
			if (e.isAutoevaluationPending) {
				observables.push(this.evaluationSvc.sendAutoevaluationMail(e.personId));
			}
		});
		//FIXME Controlar cuales fallan y dar feedback
		combineLatest(observables).subscribe(
			(data) => {
				this.showSuccessDialog();
			},
			(error) => {
				this.showErrorDialog();
			},
		);
	}

	private showSuccessDialog() {
		const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
			panelClass: ["panel-feedbalia"],
			disableClose: false,
			autoFocus: false,
			data: {
				confirmTitle: "Notificaciones por email enviadas correctamente.",
				showSuccess: true,
			},
		});
	}

	private showErrorDialog() {
		const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
			panelClass: ["panel-feedbalia"],
			disableClose: false,
			autoFocus: false,
			data: {
				confirmTitle: "Se ha producido un error al enviar las notificaciones.",
				showError: true,
			},
		});
	}

	private selectPeriod(periods) {
		let findCurrentPeriod = false;
		// Si la fecha actual está entre el comienzo y un final de un periodo, se selecciona como adtivo
		periods.forEach((p) => {
			const startPeriod = moment(p.startPeriod);
			const endPeriod = moment(p.endPeriod);
			const now = moment();
			if (now.isBetween(startPeriod, endPeriod, undefined, "[]")) {
				findCurrentPeriod = true;
				this.selectedPeriod = p.id;
			}
		});
		// Si no se ha encontrado periodo activo, se selecciona el úlitmo.
		if (!findCurrentPeriod) {
			this.selectedPeriod = periods[0].id;
		}
	}

	private _filterDepartaments(value: string): string[] {
		if (value) {
			const filterValue = value.toLowerCase();
			return this.departamentList.filter((option) => option.departamento.toLowerCase().includes(filterValue));
		} else {
			return this.departamentList;
		}
	}
}
