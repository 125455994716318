<h2 mat-dialog-title>{{ titulo }}</h2>
<mat-dialog-content class="mat-typography">
	<div [innerHTML]="contentDialog"></div>
	<!-- <p>Además de consultar <b>datos de interés</b> sobre tu participación en evaluaciones de la organización, desde este
        panel podrás <b>"Autoevaluarte"</b> siempre que quieras. Incluso aunque no haya una evaluación en curso. Aprovecha
        para conocer bien tus criterios de evaluación y la misión de tu puesto. 
        <br><br>
        Si eres <b>Evaluador</b>, utiliza
        los diferentes botones de colores para acceder directamente a tus evaluaciones pendientes, enviadas y totales.
        Podrás conocer en cualquier momento, el número de evaluaciones que te corresponden.
    </p> -->
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-raised-button mat-dialog-close class="gap-1">
		<mat-icon class="mr-4">thumb_up</mat-icon>
		{{ "tour.btn.ok" | translate }}
	</button>
</mat-dialog-actions>
