import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { PageEvent } from "@angular/material/paginator";

import { Router } from "@angular/router";
import { EvaluationService } from "app/services/evaluation/evaluation.service";
import { ImagesService } from "app/services/helper/images.service";
import moment from "moment";
@Component({
	selector: "app-dialog-notas-evaluacion",
	templateUrl: "./dialog-notas-evaluacion.component.html",
	styleUrls: ["./dialog-notas-evaluacion.component.scss"],
})
export class DialogNotasEvaluacionComponent implements OnInit {
	personas: Array<any> = [];
	evaluado: any;
	period: any;
	procesando: boolean = false;
	page = 0;
	allperiod = 0;
	totalNotas = 0;

	notas = [];
	selectedNota: null;
	newNotaName = "";

	notaActualizar = "";

	public displayedColumns: string[] = ["nota", "fecha", "actions"];

	constructor(
		private formBuilder: UntypedFormBuilder,
		private evSvc: EvaluationService,
		private router: Router,
		@Inject(MAT_DIALOG_DATA) public dialogData: any,
		private imagesSvc: ImagesService,
		private dialogRef: MatDialogRef<DialogNotasEvaluacionComponent> // Inyecta MatDialogRef
	) {
		this.evaluado = dialogData.evaluado;
		this.period = dialogData.period;
	}
	ngOnInit() {
		this.getNotas();
	}

	cerrar() {
		this.dialogRef.close();
	}

	getNotas() {
		this.evSvc.getAnnotationes(8, this.page, this.evaluado, { period: this.period.id, allperiod: this.allperiod }).subscribe((data) => {
			this.notas = data.anotaciones;
			console.log(this.notas);
		});
		this.evSvc.getAnnotationesTotal(this.evaluado, { period: this.period.id, allperiod: this.allperiod }).subscribe((data) => {
			this.totalNotas = data.total;
			console.log(this.totalNotas);
		});
	}

	parseDate(date) {
		if (date) {
			return moment(date).format("DD-MM-YYYY HH:mm");
		} else {
			return "";
		}
	}

	pageChanged(event: PageEvent) {
		this.page = event.pageIndex;
		this.getNotas();
	}

	eliminar(nota) {
		this.evSvc.deleteNota(nota.id).subscribe((data) => {
			this.getNotas();
		});
	}
	anadirNota(menuTrigger: MatMenuTrigger) {
		this.evSvc.postNota({ nota: this.newNotaName, personId: this.evaluado }).subscribe((data) => {
			this.newNotaName = "";
			this.getNotas();
			menuTrigger.closeMenu();
		});
	}
	editarNota(nota, menuTrigger: MatMenuTrigger) {
		this.evSvc.updateNota(nota.id, this.notaActualizar).subscribe((data) => {
			this.notaActualizar = "";
			this.getNotas();
			menuTrigger.closeMenu();
		});
	}

	changeAllperiod($event) {
		this.allperiod = $event.checked ? 1 : 0;
		this.page = 0;
		this.getNotas();
	}
	selectNota(nota) {
		this.notaActualizar = String(JSON.parse(JSON.stringify(nota)));
	}
}
